<template>
  <page-header title="Пользователи">
    <q-btn @click="showAddUserDialog" color="primary">
      <img src="../../assets/icons/button/add.svg" class="xs" title="Добавить пользователя" alt="">
      <span class="gt-xs">Добавить пользователя</span>
    </q-btn>
  </page-header>

  <template v-if="users != null">
    <q-card class="row q-mb-md q-px-sm q-py-md">
      <div class="col q-px-sm" hidden>
        <q-input v-model="filterForm.q" outlined label="Поиск по имени" @keyup="onFilterChange" />
      </div>
      <div class="col q-px-sm">
        <q-select v-model="filterForm.role" outlined :options="rolesOptions" label="Роль пользователя" @update:model-value="onFilterChange" />
      </div>
      <div class="col q-px-sm">
        <q-select v-model="filterForm.status" outlined :options="statusOptions" label="Статус" @update:model-value="onFilterChange" />
      </div>
    </q-card>

    <template v-if="users.length > 0">
      <q-card class="t-table">
        <div class="t-row t-header">
          <div class="t-field t-field-name">Имя</div>
          <div class="t-field f-field-email">Электронная почта</div>
          <div class="t-field t-field-registration-date">Дата регистрации</div>
          <div class="t-field t-field-status">Статус</div>
          <div class="t-field t-field-actions"></div>
        </div>

        <div v-for="user in users" :key="user.id" class="t-row">
          <div class="t-field t-field-name">
            <router-link :to="{ name: 'users_view', params: { id: user.id }}">{{ user.name }}</router-link>
          </div>
          <div class="t-field f-field-email">
            <div class="t-label">Электронная почта</div>
            {{ user.email }}
          </div>
          <div class="t-field t-field-registration-date">
            <div class="t-label">Дата регистрации</div>
            {{ formatDate(user.registrationDate) }}
          </div>
          <div class="t-field t-field-status">
            <span v-if="user.blocked" class="user-status user-status--blocked">Заблокирован</span>
            <span v-else class="user-status user-status--active">Активен</span>
          </div>
          <div class="t-field t-field-actions">
            <user-context-menu :user="user" :hook="reload" />
          </div>
        </div>
      </q-card>

      <pagination :page="filter.page" :pages="pages" :to-fn="paginationFn" />
    </template>
    <q-card v-else class="q-pa-lg">
      Пользователи не найдены.
    </q-card>
  </template>
</template>

<script>
import loading from '../../loading.js'
import { date } from 'quasar'
import { api as usersApi } from '../../api/users.js'
import PageHeader from '../../blocks/PageHeader.vue'
import AddUserDialog from '../../dialogs/users/EditUserDialog.vue'
import UserContextMenu from '../../blocks/users/UserContextMenu.vue'
import Pagination from '../../blocks/Pagination.vue'
import roles from '../../roles.js'

const createFilter = function(query) {
  const filter = {
    page: 1,
    q: null,
    role: null,
    status: null,
  }

  if (query['page']) {
    filter.page = parseInt(query['page'])
  }
  if (query['q']) {
    filter.q = query['q']
  }
  if (query['role']) {
    filter.role = query['role']
  }
  if (query['status']) {
    filter.status = query['status']
  }

  return filter
}

async function preparePage(callback, to) {
  loading.start()
  const filter = createFilter(to.query)
  const users = await usersApi.findBy(filter)
  const setInitData = vm => {
    vm.users = users
    vm.pages = users.meta.pages
    vm.setFilter(filter)
  }
  callback(setInitData)
  loading.stop()
  loading.updateTitle(to.meta.title || '')
}

export default {
  components: {
    PageHeader,
    UserContextMenu,
    Pagination,
  },
  async beforeRouteEnter (to, from, next) {
    await preparePage(setInitData => {
      next(setInitData)
    }, to)
  },
  async beforeRouteUpdate(to, from, next) {
    await preparePage(setInitData => {
      setInitData(this)
      next()
    }, to)
  },
  setup() {
    const rolesOptions = [{ label: 'Все', value: null }]
    roles.loginables().map(role => {
      rolesOptions.push({ label: role.title, value: role.id })
    })

    const statusOptions = [
      { label: 'Все', value: null },
      { label: 'Активные', value: 'active' },
      { label: 'Заблокированные', value: 'blocked' },
    ]

    return {
      rolesOptions,
      statusOptions,
    }
  },
  data() {
    return {
      users: null,
      filter: {
        page: 1,
        role: null,
        status: null,
      },
      filterForm: {
        q: '',
        role: { label: 'Все', value: null },
        status: { label: 'Все', value: null },
      },
      pages: 0,
    }
  },
  methods: {
    setFilter(filter) {
      this.filter = filter
      if (filter.q) {
        this.filterForm.q = filter.q
      }
      if (filter.role) {
        this.filterForm.role = this.rolesOptions.find(role => role.value === filter.role)
      }
      if (filter.status) {
        this.filterForm.status = this.statusOptions.find(status => status.value === filter.status)
      }
    },
    async reload() {
      const users = await usersApi.findBy(this.filter)
      this.users = users
      this.pages = users.meta.pages
    },
    showAddUserDialog() {
      const user = usersApi.create()
      user.role = roles.student()
      this.$root.createDialog({
        component: AddUserDialog,
        componentProps: {
          user: user,
          hook: this.reload,
        }
      }).onOk(user => {
        this.$root.showSuccessMessage(`Пользователь ${user.name} добавлен.`)
      })
    },
    formatDate(timeStamp) {
      return date.formatDate(timeStamp, 'DD.MM.YYYY')
    },
    applyFilterForm() {
      const query = {}
      if (this.filterForm.q) {
        query.q = this.filterForm.q
      }
      if (this.filterForm.role.value) {
        query.role = this.filterForm.role.value
      }
      if (this.filterForm.status.value) {
        query.status = this.filterForm.status.value
      }
      this.$router.push({ name: 'users_list', query: query })
    },
    onFilterChange() {
      this.applyFilterForm()
    },
    paginationFn(page) {
      const filter = {}
      if (page > 1) {
        filter.page = page
      }
      if (this.filter.q) {
        filter.q = this.filter.q
      }
      if (this.filter.role) {
        filter.role = this.filter.role
      }
      if (this.filter.status) {
        filter.status = this.filter.status
      }

      return { query: filter }
    },
  },
}
</script>

<style lang="scss">
.user-status {
  position: relative;
  color: #2AB68C;
  padding-left: 15px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 7px;
    display: inline-block;
    width: 8px;
    height: 8px;
    background: #2AB68C;
    border-radius: 4px;
  }

  &--blocked {
    color: #F36565;

    &::before {
      background: #F36565;
    }
  }
}
</style>
